<template>
  <div>
    <v-card flat>
      <v-toolbar flat>
        <v-toolbar-title> Product Specification</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          color="secondary"
          :loading="saving"
          :disabled="!item.productId"
          @click="saveCargoItem()"
          ><v-icon>save</v-icon></v-btn
        >
        <v-btn text @click="$emit('close')">X</v-btn>
      </v-toolbar>
      <v-card-text class="my-0 py-0">
        <v-list>
          <v-autocomplete
            class="my-2"
            hide-details
            hide-no-data
            hide-selected
            label="Product*"
            dense
            outlined
            :items="filterProducts"
            clearable
            item-text="name"
            item-value="id"
            :menu-props="{ closeOnContentClick: true }"
            @change="setProduct"
            v-model="item.product"
            return-object
            :loading="loadingProduct"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.item.type }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  v-if="data.item.parentProduct"
                  style="font-size: 12px"
                >
                  Parent: {{ data.item.parentProduct.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-autocomplete
            :key="productKey"
            class="my-2"
            hide-details
            hide-no-data
            hide-selected
            label="Variety"
            :disabled="!item.product"
            dense
            outlined
            :items="filterVarieties"
            clearable
            item-text="name"
            item-value="id"
            :menu-props="{ closeOnContentClick: true }"
            @change="setVariety"
            v-model="item.varietyProduct"
            return-object
            :loading="loadingProduct"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.item.type }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  v-if="data.item.parentProduct"
                  style="font-size: 12px"
                >
                  Parent: {{ data.item.parentProduct.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-autocomplete
            class="my-0 py-0"
            :items="availablePUC"
            chips
            deletable-chips
            small-chips
            multiple
            :key="pucKey"
            ref="pucReference"
            v-model="selectedPUC"
            label="PUC"
            item-text="value"
            item-value="value"
            outlined
            dense
          >
            <template v-slot:append-item>
              <v-divider class="mb-2"></v-divider>
              <v-list-item
                style="height: 30px"
                @click="addVariation('Add PUC', 'puc')"
              >
                <v-list-item-content class="text-center">
                  <v-list-item-title class="text-center">
                    <v-btn small color="primary" text>Add as new PUC</v-btn>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
          <v-autocomplete
            class="my-0 py-0"
            :items="availablePHC"
            chips
            deletable-chips
            small-chips
            multiple
            :key="phcKey"
            ref="phcReference"
            v-model="selectedPHC"
            label="PHC"
            item-text="value"
            item-value="value"
            outlined
            dense
          >
            <template v-slot:append-item>
              <v-divider class="mb-2"></v-divider>
              <v-list-item
                style="height: 30px"
                @click="addVariation('Add PHC', 'phc')"
              >
                <v-list-item-content class="text-center">
                  <v-list-item-title class="text-center">
                    <v-btn small color="primary" text>Add new PHC</v-btn>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
          <v-autocomplete
          class="my-0"
            :items="availablePackCodes"
            chips
            deletable-chips
            small-chips
            multiple
            :key="packKey"
            ref="packCodeReference"
            v-model="selectedPackCodes"
            label="Pack Code"
            item-text="value"
            item-value="value"
            outlined
            dense
          >
            <template v-slot:append-item>
              <v-divider class="mb-2"></v-divider>
              <v-list-item
                style="height: 30px"
                @click="addVariation('Add Pack Code', 'packCode')"
              >
                <v-list-item-content class="text-center">
                  <v-list-item-title class="text-center">
                    <v-btn small color="primary" text>Add new Pack Code</v-btn>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
          <v-autocomplete
          class="my-0"
            :items="availableClass"
            chips
            deletable-chips
            small-chips
            multiple
            :key="classKey"
            ref="gradeReference"
            v-model="selectedClass"
            label="Class"
            item-text="value"
            item-value="value"
            outlined
            dense
          >
            <template v-slot:append-item>
              <v-divider class="mb-2"></v-divider>
              <v-list-item
                style="height: 30px"
                @click="addVariation('Add Class', 'grade')"
              >
                <v-list-item-content class="text-center">
                  <v-list-item-title class="text-center">
                    <v-btn small color="primary" text>Add new Class</v-btn>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
          <v-autocomplete
          class="my-0 mt-1"
            :items="availableCount"
            chips
            deletable-chips
            small-chips
            multiple
            :key="countKey"
            return-object
            ref="countReference"
            v-model="selectedCount"
            label="Count/Size"
            item-text="value"
            item-value="value"
            outlined
            dense
          >
            <template v-slot:append-item>
              <v-divider class="mb-2"></v-divider>
              <v-list-item
                style="height: 30px"
                @click="addVariation('Add Count', 'countSize')"
              >
                <v-list-item-content class="text-center">
                  <v-list-item-title class="text-center">
                    <v-btn small color="primary" text>Add as new Count</v-btn>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-list>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="addVariationModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            {{ addVariationType.title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="secondary" @click="saveVariation()"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn
            text
            @click="(addVariationModal = false), (addVariationType = {})"
            >X</v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-text-field
            autofocus
            placeholder="Add variation"
            v-model="addVariationType.value"
            outlined
            dense
            clearable
          ></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["cargoItem", "autosave", "bookingId"],
  data: () => ({
    addVariationModal: false,
    addVariationType: {},
    availableData: [],
    item: {},
    loadingProduct: false,
    products: {
      total: 0,
      data: [],
    },
    productKey: 100,
    saving: false,
    selectedClass: [],
    selectedCount: [],
    selectedPUC: [],
    selectedPHC: [],
    selectedPackCodes: [],
    variationKey: 200,
    countKey: 300,
    phcKey: 400,
    pucKey: 500,
    packKey: 600,
    classKey: 700
  }),
  watch: {
    cargoItem(val) {
      if (val) {
        this.item = { ...val };
        let classArray = val.orderProductSpecifications ? val.orderProductSpecifications.filter(x=>x.key == 'grade'):[]
        let countArray = val.orderProductSpecifications ? val.orderProductSpecifications.filter(x=>x.key == 'countSize'):[]
        let pucArray = val.orderProductSpecifications ? val.orderProductSpecifications.filter(x=>x.key == 'puc'):[]
        let phcArray = val.orderProductSpecifications ? val.orderProductSpecifications.filter(x=>x.key == 'phc'):[]
        let packCodeArray = val.orderProductSpecifications ? val.orderProductSpecifications.filter(x=>x.key == 'packCode'):[]

        this.selectedClass= classArray
        this.selectedCount = countArray
        this.selectedPUC = pucArray
        this.selectedPHC = phcArray
        this.selectedPackCodes = packCodeArray
        this.availableData = val.orderProductSpecifications ? val.orderProductSpecifications:[]
      } else {
        this.item = {};
        this.selectedClass= []
        this.selectedCount = []
        this.selectedPUC = []
        this.selectedPHC = []
        this.selectedPackCodes =[]
      }
    }
  },
  computed: {
    availableCount() {
      let result = this.availableData.filter((x) => x.key == "countSize");
      return result;
    },
    availableClass() {
      let result = this.availableData.filter((x) => x.key == "grade");
      return result;
    },
    availablePUC() {
      let result = this.availableData.filter((x) => x.key == "puc");
      return result;
    },
    availablePHC() {
      let result = this.availableData.filter((x) => x.key == "phc");
      return result;
    },
    availablePackCodes() {
      let result = this.availableData.filter((x) => x.key == "packCode");
      return result;
    },

    filterProducts() {
      let result = this.products.data;
      result = result.filter((x) => x.type == "Product");
      return result;
    },
    filterVarieties() {
      let result = this.products.data;
      if (this.item && this.item.productId) {
        result = result.filter(
          (x) => x.type == "Variety" && x.parentProductId == this.item.productId
        );
      } else {
        result = [];
      }
      return result;
    },
  },
  created() {
    this.loadProducts();
  },
  methods: {
    addVariation(title, type) {
      this.addVariationType = {
        title,
        key: type,
        value: null,
      };
      this.addVariationModal = true;
      this.$nextTick(() => {
        document.activeElement.blur();

        // console.log(this.$refs[type+'Reference'])
        //     this.$refs[type+'Reference'].$refs.input.unfocus();
      });
    },
    async loadProducts() {
      this.loadingProduct = true;
      this.products = await this.$API.getProducts({});
      this.loadingProduct = false;
      if(this.cargoItem){
        this.item = { ...this.cargoItem };
        let classArray = this.cargoItem.orderProductSpecifications ? this.cargoItem.orderProductSpecifications.filter(x=>x.key == 'grade'):[]
        let countArray = this.cargoItem.orderProductSpecifications ? this.cargoItem.orderProductSpecifications.filter(x=>x.key == 'countSize'):[]
        let pucArray = this.cargoItem.orderProductSpecifications ? this.cargoItem.orderProductSpecifications.filter(x=>x.key == 'puc'):[]
        let phcArray = this.cargoItem.orderProductSpecifications ? this.cargoItem.orderProductSpecifications.filter(x=>x.key == 'phc'):[]
        let packCodeArray = this.cargoItem.orderProductSpecifications ? this.cargoItem.orderProductSpecifications.filter(x=>x.key == 'packCode'):[]

        this.selectedClass= classArray
        this.selectedCount = countArray
        this.selectedPUC = pucArray
        this.selectedPHC = phcArray
        this.selectedPackCodes = packCodeArray
        this.availableData = this.cargoItem.orderProductSpecifications ? this.cargoItem.orderProductSpecifications:[]
      }
    },
    async saveCargoItem() {
        this.item.orderProductSpecifications = [
        ...this.selectedPUC, ...this.selectedPHC, ...this.selectedClass, ...this.selectedCount, ...this.selectedPackCodes
      ]
      if(this.autosave){
        this.saving = true
        if(!this.item.id){
          let result = await this.$API.createOrderItem(this.bookingId, this.item)
          this.$emit("save", result);
        } else {
          let result = await this.$API.updateOrderItem(this.item)
          this.$emit("save", result);
        }
        this.saving = false
      } else {
      this.$emit("save", this.item);
      }
    },
    saveVariation() {
      this.availableData.push({
        key: this.addVariationType.key,
        value: this.addVariationType.value,
      });

      switch (this.addVariationType.key) {
        case "puc":
          this.selectedPUC.push({
            key: this.addVariationType.key,
            value: this.addVariationType.value,
          });
          break;

        case "phc":
          this.selectedPHC.push({
            key: this.addVariationType.key,
            value: this.addVariationType.value,
          });
          break;

        case "grade":
          this.selectedClass.push({
            key: this.addVariationType.key,
            value: this.addVariationType.value,
          });
          break;
        case "packCode":
          this.selectedPackCodes.push({
            key: this.addVariationType.key,
            value: this.addVariationType.value,
          });
          break;
        case "countSize":
          this.selectedCount.push({
            key: this.addVariationType.key,
            value: this.addVariationType.value,
          });
          break;
      }
      this.countKey ++
      this.phcKey ++
      this.pucKey ++
      this.packKey ++
      this.classKey ++
      this.addVariationModal = false;
      this.addVariationType = {};
      this.variationKey++;
    },
    setProduct() {
      if (this.item.product) {
        this.item.productId = this.item.product.id;
      } else {
        this.item.productId = null;
        this.item.varietyProduct = null;
        this.item.varietyProductId = null;
      }
      this.productKey++;
    },
    setVariety() {
      if (this.item.varietyProduct) {
        this.item.varietyProductId = this.item.varietyProduct.id;
      } else {
        this.item.varietyProductId = null;
      }
    },
  },
};
</script>